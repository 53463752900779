.divider {
  --divider-color: var(--content-dark-primary-min);

  background-color: var(--divider-color);
  border: none;
  min-height: 1px;
  min-width: 1px;
}

.divider_dark {
  --divider-color: var(--content-dark-primary-low);
}

.divider_text {
  align-items: center;
  background-color: transparent;
  color: var(--content-dark-primary-medium);
  display: flex;
  gap: var(--spacing-basic-3xs);
}

.divider_text::after,
.divider_text::before {
  background-color: var(--divider-color);
  content: '';
  flex: 1;
  min-height: 1px;
  min-width: 1px;
}
