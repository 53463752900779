.loading {
  display: grid;
  height: 100%;
  padding: theme(spacing.8);
  width: 100%;
}

.loading__progress {
  color: var(--content-dark-brand);
  /* stylelint-disable-next-line unit-no-unknown */
  font-size: theme(fontSize.4xl);
  place-self: center;
}
