.skeleton {
  @apply block animate-pulse bg-light-primary-high;
}

.skeleton_circular {
  @apply aspect-square rounded-full;
}

.skeleton_rectangular {
  /* for customization purpose  */
}

.skeleton_text {
  /*
   * Values are inspired by MUI Skeleton component
   * https://github.com/mui/material-ui/blob/4e5e2a7a4634bd13636fe22c8acf0605f71c08a9/packages/mui-material/src/Skeleton/Skeleton.js#L88-L89
   */
  transform: scale(1, 0.8);
  transform-origin: 0 55%;
}

.skeleton_text:empty::before {
  content: '\00a0';
}
