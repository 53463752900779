.cross-sell-horizontal-slider {
  @apply relative;
}

.cross-sell-horizontal-slider__slider {
  @apply -mx-1 flex snap-x overflow-x-auto scroll-smooth tablet:scrollbar-hide;
}

.cross-sell-horizontal-slider__slider_no-items {
  @apply overflow-x-hidden;
}

.cross-sell-horizontal-slider__item-wrapper {
  @apply flex shrink-0 grow-0 snap-start flex-col overflow-x-hidden px-1;

  flex-basis: 46%;
}

.cross-sell-horizontal-slider__navigation-button {
  @apply top-1/2 -translate-y-1/2 transition-opacity;

  display: none;
  opacity: 0.25;
  position: absolute;
}

.cross-sell-horizontal-slider:hover
  .cross-sell-horizontal-slider__navigation-button {
  opacity: 1;
}

@media screen(mobile) {
  .cross-sell-horizontal-slider__item-wrapper {
    flex-basis: 30%;
  }
}

@media screen(tablet) {
  .cross-sell-horizontal-slider__item-wrapper {
    flex-basis: calc(100% / var(--items-per-page));
  }

  .cross-sell-horizontal-slider__navigation-button {
    display: flex;
  }

  .cross-sell-horizontal-slider__navigation-button[hidden] {
    display: none;
  }
}
