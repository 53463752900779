.checkbox {
  appearance: none;
  background-color: var(--background-light-primary-low);
  border: 1px solid var(--background-light-primary-max);
  border-radius: 4px;
  height: var(--checkbox-size);
  position: relative;
  width: var(--checkbox-size);
}

.checkbox_medium {
  --checkbox-size: 24px;
  --checkbox-check-size: 14px;
}

.checkbox_small {
  --checkbox-size: 20px;
  --checkbox-check-size: 10px;
}

.checkbox:required {
  border-color: var(--content-dark-brand);
}

.checkbox:checked {
  border-color: var(--content-dark-brand);
}

.checkbox:checked::before {
  color: var(--content-dark-brand);
  content: '✓';
  font-size: var(--checkbox-check-size);
  height: var(--checkbox-check-size);
  left: 50%;
  line-height: 1em;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: var(--checkbox-check-size);
}

.checkbox:disabled {
  background-color: var(--background-light-primary-medium);
  border-color: var(--background-light-primary-max);
}

.checkbox:disabled:checked::before {
  color: var(--content-dark-primary-medium);
}
