.select-popup {
  /*
   * Experimental solution from MUI Joy
   * https://github.com/mui/material-ui/blob/7f81475ea148a416ec8fab252120ce6567c62897/packages/mui-joy/src/Select/Select.tsx#L240-L241
   */
  z-index: var(--unstable-popup-z-index, theme(zIndex.popup));
}

.select-popup[data-popup-placement='bottom-start'] {
  /* stylelint-disable-next-line unit-no-unknown */
  border-bottom-left-radius: theme(borderRadius.3xl);
  /* stylelint-disable-next-line unit-no-unknown */
  border-bottom-right-radius: theme(borderRadius.3xl);
  border-top-width: 0;
}

.select-popup[data-popup-placement='top-start'] {
  border-bottom-width: 0;
  /* stylelint-disable-next-line unit-no-unknown */
  border-top-left-radius: theme(borderRadius.3xl);
  /* stylelint-disable-next-line unit-no-unknown */
  border-top-right-radius: theme(borderRadius.3xl);
}

/* https://github.com/tailwindlabs/tailwindcss/issues/9908#issuecomment-1327887975 */
@media not all and (min-width: theme('screens.tablet')) {
  /* Cover edge case to disable popper positioning for mobile screen only */
  .select-popup {
    position: fixed !important;
    transform: none !important;
  }
}

@media screen(tablet) {
  .select-popup {
    background-color: var(--background-light-primary-low);
    border: 1px solid var(--content-dark-primary-medium);
    overflow: hidden;
  }
}
