.loading-button {
  @apply relative;
}

.loading-button__progress {
  @apply text-[30px];
}

.loading-button__progress_start {
  /* for customization purpose  */
}

.loading-button__progress_center {
  @apply absolute left-1/2 -translate-x-1/2;
}

.loading-button__progress_end {
  /* for customization purpose  */
}

.loading-button__content {
  /* for customization purpose  */
}

.loading-button__content_loading {
  @apply invisible;
}
