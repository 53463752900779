.slider-button {
  --slider-button-size: 56px;

  align-items: center;
  background-color: theme(backgroundColor.light-primary-low);
  border-radius: theme(borderRadius.full);
  box-shadow: 0 4px 4px 0 #dadada;
  color: theme(textColor.dark-brand);
  display: inline-flex;
  height: var(--slider-button-size);
  justify-content: center;
  width: var(--slider-button-size);
}

.slider-button__icon {
  --slider-button-icon-size: 42px;

  height: var(--slider-button-icon-size);
  width: var(--slider-button-icon-size);
}
