/* Inspired by https://github.com/mui/material-ui/blob/fc23da8a6bc0872d7ebd71b7f78c7502aeb95aa3/packages/mui-material/src/CircularProgress/CircularProgress.js */

@keyframes animation-loading-indicator-circle {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }

  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}

.circular-progress svg {
  @apply h-[1em] w-[1em] animate-[spin_1.4s_linear_infinite];
}

.circular-progress circle {
  animation: animation-loading-indicator-circle 1.4s ease-in-out 0s infinite
    normal none running;
  fill: none;
  stroke: currentcolor;
  stroke-dasharray: 80 200;
  stroke-dashoffset: 0;
  stroke-width: 3.6;
}

.circular-progress__screen-reader {
  @apply sr-only;
}
