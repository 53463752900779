@import url('./Popup.css');
@import url('./Root.css');
@import url('./Listbox.css');

/**
 * Hide input for HTML validation as it appears outside of component.
 * Issue is introduced by https://github.com/mui/material-ui/pull/38167.
 * To resolve it we should create custom Select component like in Joy theme with useSelect hook
 * rather than using Base Select.
 */
.select-root + input[aria-hidden='true'][tabIndex='-1'] {
  display: none;
}
