.tab {
  border-color: transparent;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-width: 1px 1px 0;
  color: var(--content-dark-primary-max);
  flex: 1;
  font-size: theme(fontSize.m);
  font-weight: theme(fontWeight.medium);
  letter-spacing: theme(letterSpacing.m);
  line-height: theme(lineHeight.m);
  padding: 12px 16px;
  white-space: nowrap;
}

.tab:hover {
  color: var(--content-dark-brand);
}

.tab[aria-selected='true'] {
  background-color: var(--background-light-primary-low);
  border-color: var(--content-dark-primary-min);
  color: var(--content-dark-primary-max);
}
