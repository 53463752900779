.counter-indicator {
  align-items: center;
  background-color: var(--counter-indicator-background-color);
  clip-path: var(--counter-indicator-path);
  color: var(--counter-indicator-content-color);
  display: inline-flex;
  font-family: theme(fontFamily.mono);
  font-size: var(--counter-indicator-font-size);
  font-variant-numeric: tabular-nums;
  font-weight: theme(fontWeight.medium);
  height: var(--counter-indicator-height);
  justify-content: center;
  width: var(--counter-indicator-width);
}

.counter-indicator_color-primary {
  --counter-indicator-background-color: var(--background-light-brand-high);
  --counter-indicator-content-color: var(--content-dark-primary-max);
}

.counter-indicator_color-secondary {
  --counter-indicator-background-color: var(--background-light-secondary-high);
  --counter-indicator-content-color: var(--content-dark-primary-max);
}

.counter-indicator_color-brand {
  --counter-indicator-background-color: var(--background-dark-brand);
  --counter-indicator-content-color: var(--content-light-primary-strong);
}

.counter-indicator_size-s {
  --counter-indicator-font-size: theme(fontSize.s);
  --counter-indicator-height: 26px;
  --counter-indicator-path: path(
    'M10.386.416a3.34 3.34 0 0 1 3.228 0L18 2.846l4.346 2.496a3.185 3.185 0 0 1 1.614 2.732L24 13l-.04 4.926a3.185 3.185 0 0 1-1.614 2.732L18 23.155l-4.386 2.43a3.34 3.34 0 0 1-3.228 0L6 23.154l-4.346-2.497A3.185 3.185 0 0 1 .04 17.926L0 13l.04-4.926a3.185 3.185 0 0 1 1.614-2.732L6 2.845l4.386-2.43Z'
  );
  --counter-indicator-width: 24px;
}

.counter-indicator_size-m {
  --counter-indicator-font-size: theme(fontSize.m);
  --counter-indicator-height: 38px;
  --counter-indicator-path: path(
    'M16.454.608a5.125 5.125 0 0 1 4.842 0l6.579 3.55 6.52 3.65c1.487.833 2.406 2.349 2.42 3.992l.06 7.2-.06 7.2c-.014 1.643-.933 3.16-2.42 3.992l-6.52 3.65-6.58 3.55c-1.5.81-3.34.81-4.84 0l-6.58-3.55-6.52-3.65C1.869 29.36.95 27.843.936 26.2L.875 19l.06-7.2c.014-1.643.933-3.16 2.421-3.992l6.519-3.65 6.58-3.55Z'
  );
  --counter-indicator-width: 36px;
}
