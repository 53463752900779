.numbered-list-item {
  align-items: flex-start;
  counter-increment: numbered-list;
  display: flex;
  gap: theme(spacing.3);
}

.numbered-list-item__counter {
  flex-shrink: 0;
}

.numbered-list-item__counter:empty::before {
  content: counter(numbered-list);
  display: inline;
}

.numbered-list-item__content {
  padding-top: theme(spacing[0.5]);
}
