.cross-sell-widget-more-link {
  @apply text-s;

  align-items: center;
  display: flex;
  font-weight: theme(fontWeight.medium);
  gap: theme(spacing.1);
  text-decoration: none;
  white-space: nowrap;
}

.cross-sell-widget-more-link_skeleton {
  width: 12rem; /* similar to translated text with icon */
}

@media screen(tablet) {
  .cross-sell-widget-more-link {
    @apply text-m;
  }
}
