.ad-server-banner {
  border-radius: 16px;
  display: flex;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.as-placeholder {
  background-color: var(--background-light-primary-medium);
  /* stylelint-disable-next-line unit-no-unknown */
  border-radius: theme(borderRadius.2xl);
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

.as-placeholder [data-as-content] {
  border: none;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.as-placeholder[data-as-status='empty'],
.as-placeholder[data-as-status='fail'] {
  display: none;
}

.as-placeholder[data-as-status='ready'] {
  /* we need to overwride the space rezerved for the banner when it is loaded */
  aspect-ratio: auto !important;
  background: transparent;
}

.as-placeholder[data-as-status='ready'] iframe {
  height: 100%;
  width: 100%;
}
