.tooltip {
  --tooltip-background: var(--content-light-primary-strong);

  background-color: var(--tooltip-background);
  border-radius: 0.5rem;
  box-shadow:
    0 4px 6px -2px rgb(68 68 68 / 6%),
    0 10px 15px -3px rgb(68 68 68 / 10%);
  color: var(--content-dark-primary-max);
  font-size: var(--type-scale-s-font-size);
  letter-spacing: var(--type-scale-s-letter-spacing);
  line-height: var(--type-scale-s-line-height);
  padding: var(--spacing-2);
  text-align: start;
  transform-origin: 50%;
  white-space: normal;
  width: 12rem;

  /*
   * Experimental solution from MUI Joy
   * https://github.com/mui/material-ui/blob/7f81475ea148a416ec8fab252120ce6567c62897/packages/mui-joy/src/Select/Select.tsx#L240-L241
   */
  z-index: var(--unstable-popup-z-index, theme(zIndex.popup));
}

.tooltip__arrow {
  --tooltip-arrow-rotate: 45deg;
  --tooltip-arrow-translate-x: 0;
  --tooltip-arrow-translate-y: 0;
  --tooltip-arrow-size: 0.75rem;

  background-color: var(--tooltip-background);
  height: var(--tooltip-arrow-size);
  position: absolute;
  transform: translateX(var(--tooltip-arrow-translate-x))
    translateY(var(--tooltip-arrow-translate-y))
    rotate(var(--tooltip-arrow-rotate));
  width: var(--tooltip-arrow-size);
}

.tooltip[data-popper-placement='top'] .tooltip__arrow {
  --tooltip-arrow-translate-x: -50%;
  --tooltip-arrow-translate-y: 50%;

  bottom: 0;
  left: 50%;
}

.tooltip[data-popper-placement='right'] .tooltip__arrow {
  --tooltip-arrow-translate-x: -50%;
  --tooltip-arrow-translate-y: -50%;

  left: 0;
  top: 50%;
}

.tooltip[data-popper-placement='left'] .tooltip__arrow {
  --tooltip-arrow-translate-x: 50%;
  --tooltip-arrow-translate-y: -50%;

  right: 0;
  top: 50%;
}

.tooltip[data-popper-placement='bottom'] .tooltip__arrow {
  --tooltip-arrow-translate-x: -50%;
  --tooltip-arrow-translate-y: -50%;

  left: 50%;
  top: 0;
}
