.link {
  text-align: start;
  text-decoration-line: underline;
  transition: color theme(transitionDuration.150)
    theme(transitionTimingFunction.DEFAULT);
}

.link:hover {
  color: var(--content-dark-hover-brand);
}

.link:focus,
.link:active {
  color: var(--content-dark-pressed-brand);
}

.link_brand {
  color: var(--content-dark-brand);
}

.link_overlay {
  position: initial;
  text-decoration-line: none;
}

.link_overlay::after {
  content: '';
  display: block;
  inset: 0;
  position: absolute;
}
