.option {
  align-items: center;
  color: var(--content-dark-primary-max);
  cursor: pointer;
  display: flex;
  gap: theme(spacing.2);
  justify-content: space-between;
  min-height: 56px;
  outline: none;
  padding: theme(spacing.3) theme(spacing.4) theme(spacing.3) theme(spacing.6);
}

.option:hover {
  background-color: var(--background-light-brand-low);
}

.option_highlighted {
  background-color: var(--background-light-brand-low);
}

.option_selected {
  background-color: var(--background-light-brand-low);
  font-weight: var(--typeface-weight-medium);
}

.option__icon {
  color: var(--content-dark-success-medium);
  height: 20px;
  width: 20px;
}
