:root {
  --top-header-sticky-banner-height: 35px;
}

.top-header-sticky-banner {
  align-items: center;
  background-color: var(--background-light-secondary-high);
  display: flex;
  min-height: var(--top-header-sticky-banner-height);
  padding-block: var(--spacing-basic-4xs);
  position: sticky;
  top: var(--sticky-header-height);
  z-index: 19;
}

@media screen(tablet) {
  .top-header-sticky-banner {
    top: var(--sticky-header-height);
  }
}

@media print {
  .header-extension {
    display: none;
  }
}
