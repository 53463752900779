.slider-root {
  padding: calc(var(--slider-thumb-size) / 2);
}

.slider-root__container {
  display: inline-flex;
  flex: 1;
  position: relative;
  top: calc(var(--slider-rail-height) / -2);
}
