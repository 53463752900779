.form-label {
  color: var(--content-dark-primary-medium);
}

.form-label_form-control {
  font-size: var(--type-scale-m-font-size);
  left: calc(var(--spacing-6) + 1px);
  letter-spacing: var(--type-scale-m-letter-spacing);
  line-height: var(--type-scale-m-line-height);
  pointer-events: none;
  position: absolute;
  top: calc(var(--spacing-4) + 1px);
  transition-duration: theme(transitionDuration.100);
  transition-property: font-size, line-height, letter-spacing, top;
  transition-timing-function: theme(transitionTimingFunction.in-out);
}

.form-label_form-control_filled,
.form-label_form-control_focused,
:where(.form-control:has(.input-inner_form-control:autofill))
  .form-label_form-control {
  font-size: var(--type-scale-xs-font-size);
  letter-spacing: var(--type-scale-xs-letter-spacing);
  line-height: var(--type-scale-xs-line-height);
  top: var(--spacing-2);
}
