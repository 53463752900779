/* stylelint-disable selector-class-pattern,selector-id-pattern -- overwriting existing styles from external library requires non BEM selector usage */

#NowAPIInfo-modal form .row {
  @apply flex flex-col tablet:flex-row;
}

#NowAPIInfo-modal form .row .col-md-6 {
  @apply w-full tablet:w-1/2;
}

#NowAPIInfo-modal form ul {
  @apply mt-3.5;
}

#NowAPIInfo-modal .NowAPIInfo-tab-content {
  @apply border-0;
}

#NowAPIInfo-modal #NowAPIInfo-zipCodeField {
  @apply w-full rounded;
}
