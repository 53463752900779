.input-inner {
  background-color: transparent;
  color: var(--content-dark-primary-max);
  flex: 1;
  min-width: 0;
  outline: none;
  padding-bottom: calc(var(--spacing-4) - 1px);
  padding-top: calc(var(--spacing-4) - 1px);
}

.input-inner:autofill {
  box-shadow: inset 0 0 0 2rem var(--background-light-primary-low);
}

.input-inner::placeholder {
  color: var(--content-dark-primary-strong);
}

.input-inner_form-control {
  transition-duration: theme(transitionDuration.100);
  transition-property: padding-bottom, padding-top;
  transition-timing-function: theme(transitionTimingFunction.in-out);
}

.input-inner_form-control:where(:not([value='']), :autofill, :focus) {
  padding-bottom: calc(var(--spacing-2) - 1px);
  padding-top: calc(var(--spacing-6) - 1px);
}
