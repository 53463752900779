.heading {
  font-size: var(--heading-font-size);
  font-weight: var(--typeface-weight-medium);
  letter-spacing: var(--heading-letter-spacing);
  line-height: var(--heading-line-height);
}

.heading--size-s {
  --heading-font-size: var(--type-scale-m-font-size);
  --heading-letter-spacing: var(--type-scale-m-letter-spacing);
  --heading-line-height: var(--type-scale-m-line-height);
}

.heading--size-m {
  --heading-font-size: var(--type-scale-m-font-size);
  --heading-letter-spacing: var(--type-scale-m-letter-spacing);
  --heading-line-height: var(--type-scale-m-line-height);
}

.heading--size-l {
  --heading-font-size: var(--type-scale-xl-font-size);
  --heading-letter-spacing: var(--type-scale-xl-letter-spacing);
  --heading-line-height: var(--type-scale-xl-line-height);
}

@media screen(desktop) {
  .heading--size-s {
    --heading-font-size: var(--type-scale-l-font-size);
    --heading-letter-spacing: var(--type-scale-l-letter-spacing);
    --heading-line-height: var(--type-scale-l-line-height);
  }

  .heading--size-m {
    --heading-font-size: var(--type-scale-xl-font-size);
    --heading-letter-spacing: var(--type-scale-xl-letter-spacing);
    --heading-line-height: var(--type-scale-xl-line-height);
  }

  .heading--size-l {
    --heading-font-size: var(--type-scale-2xl-font-size);
    --heading-letter-spacing: var(--type-scale-2xl-letter-spacing);
    --heading-line-height: var(--type-scale-2xl-line-height);
  }
}
