@import url('../Accordion/Accordion.css');

.accordion-stack {
  /* for customization purpose  */
}

:where(.accordion-stack) > :not(.accordion) {
  display: none;
}

:where(.accordion-stack) .accordion:where(:not(:first-of-type)) {
  --accordion-border-top-radius: var(--accordion-border-width);

  margin-top: calc(var(--accordion-border-width) * -1);
}

:where(.accordion-stack) .accordion:where(:not(:last-of-type)) {
  --accordion-border-bottom-radius: var(--accordion-border-width);
}
