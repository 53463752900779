.accordion-summary {
  --accordion-summary-background: var(--background-light-primary-medium);
  --accordion-summary-border-top-radius: calc(
    var(--accordion-border-top-radius) - var(--accordion-border-width)
  );
  --accordion-summary-border-bottom-radius: calc(
    var(--accordion-border-bottom-radius) - var(--accordion-border-width)
  );

  align-items: center;
  background-color: var(--accordion-summary-background);
  border-radius: var(--accordion-summary-border-top-radius)
    var(--accordion-summary-border-top-radius)
    var(--accordion-summary-border-bottom-radius)
    var(--accordion-summary-border-bottom-radius);
  display: flex;
  gap: var(--spacing-2);
  justify-content: space-between;
  outline: none;
  padding-inline: var(--spacing-content-large);
  transition-duration: theme(transitionDuration.150);
  transition-property: background-color, border-radius;
  transition-timing-function: theme(transitionTimingFunction.in-out);
  user-select: none;
}

.accordion-summary:hover {
  --accordion-summary-background: var(--background-light-primary-high);
}

.accordion-summary:focus-visible {
  --accordion-summary-background: var(--background-light-primary-high);
}

.accordion-summary_expanded {
  --accordion-summary-border-bottom-radius: 0;
}

.accordion-summary__content {
  color: var(--content-dark-primary-max);
  font-size: var(--type-scale-m-font-size);
  font-weight: var(--typeface-weight-medium);
  letter-spacing: var(--type-scale-m-letter-spacing);
  line-height: var(--type-scale-m-line-height);
  padding-block: var(--spacing-content-medium);
}

.accordion-summary__expand-icon-wrapper {
  color: var(--content-dark-brand);
  font-size: 1.5rem;
  transition-duration: theme(transitionDuration.150);
}

.accordion-summary__expand-icon-wrapper_expanded {
  transform: rotate(180deg);
}

@media print {
  .accordion-summary__expand-icon-wrapper {
    display: none;
  }
}
