@import url('./AccordionDetails.css');
@import url('./AccordionSummary.css');

.accordion {
  --accordion-border-radius: 1.75rem;
  --accordion-border-width: 1px;
  --accordion-border-top-radius: var(--accordion-border-radius);
  --accordion-border-bottom-radius: var(--accordion-border-radius);

  background-color: var(--background-light-primary-low);
  border: var(--accordion-border-width) solid var(--content-dark-primary-min);
  border-radius: var(--accordion-border-top-radius)
    var(--accordion-border-top-radius) var(--accordion-border-bottom-radius)
    var(--accordion-border-bottom-radius);
}

.accordion__transition {
  transition: max-height theme(transitionDuration.150)
    theme(transitionTimingFunction.out);
}

.accordion__transition_collapsed {
  max-height: 0;
  overflow: hidden;
}

@media print {
  .accordion__transition_collapsed {
    max-height: none;
  }
}
