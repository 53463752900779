@import url('tailwindcss/base.css');
@import url('design-tokens/tokens.css') layer(base);
@import url('./base.css') layer(base);

@import url('tailwindcss/components.css');
@import url('base-ui/components.css') layer(components);
@import url('./components.css') layer(components);

/*
 * Working with third-party libraries
 * https://tailwindcss.com/docs/content-configuration#working-with-third-party-libraries
 */
@import url('./trustedshops-badge.css');
@import url('../domain/product-page/now/components/ProductHeadNow.css');

@import url('./utilities.css') layer(utilities);
@import url('tailwindcss/utilities.css');
