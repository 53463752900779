.slider__thumb {
  background-color: var(--background-light-brand-high);
  border-radius: theme(borderRadius.full);
  height: var(--slider-thumb-size);
  margin-block-start: calc(
    (var(--slider-thumb-size) / -2) + (var(--slider-rail-height) / 2)
  );
  margin-inline-start: calc(var(--slider-thumb-size) / -2);
  position: absolute;
  width: var(--slider-thumb-size);
}
