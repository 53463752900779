.radio {
  appearance: none;
  background-color: var(--background-light-primary-low);
  border: 1px solid var(--background-light-primary-max);
  border-radius: 50%;
  height: var(--radio-size);
  position: relative;
  width: var(--radio-size);
}

.radio_medium {
  --radio-size: 24px;
  --radio-check-size: 14px;
}

.radio_small {
  --radio-size: 20px;
  --radio-check-size: 10px;
}

.radio:required {
  border-color: var(--content-dark-brand);
}

.radio:checked {
  border-color: var(--content-dark-brand);
}

.radio:checked::before {
  background-color: var(--content-dark-brand);
  border-radius: 50%;
  content: '';
  height: var(--radio-check-size);
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: var(--radio-check-size);
}

.radio:disabled {
  background-color: var(--background-light-primary-medium);
  border-color: var(--background-light-primary-max);
}

.radio:disabled:checked::before {
  background-color: var(--background-light-primary-max);
}
