.notification {
  align-items: center;
  background-color: var(--notification-background);
  border-radius: 1rem;
  color: var(--notification-color);
  display: flex;
  gap: theme(gap.3);
  padding: theme(padding.4);
}

.notification_error {
  --notification-background: var(--background-light-error);
  --notification-icon-background: var(--background-light-error);
  --notification-color: var(--content-dark-error-strong);
}

.notification_info {
  --notification-background: var(--background-light-secondary-medium);
  --notification-icon-background: var(--background-light-secondary-medium);
  --notification-color: var(--content-dark-secondary-strong);
}

.notification_success {
  --notification-background: var(--background-light-success);
  --notification-icon-background: var(--background-light-success);
  --notification-color: var(--content-dark-success-strong);
}

.notification_warning {
  --notification-background: var(--background-light-warning);
  --notification-icon-background: var(--background-light-warning);
  --notification-color: var(--content-dark-warning-strong);
}

.notification_primary {
  --notification-icon-background: var(--background-light-primary-low);
}

.notification_secondary {
  --notification-background: var(--background-light-primary-low);
}

.notification__icon-container {
  align-self: flex-start;
  background-color: var(--notification-icon-background);
  border-radius: theme(borderRadius.full);
  flex-shrink: 0;
  font-size: 1.25rem;
  padding: theme(padding[2.5]);
}

.notification__icon {
  /* for customization purpose  */
}

.notification__title {
  font-size: theme(fontSize.s);
  font-weight: theme(fontWeight.medium);
  letter-spacing: theme(letterSpacing.s);
  line-height: theme(lineHeight.s);
  margin-bottom: theme(margin.1);
}

.notification__text {
  color: var(--content-dark-primary-max);
}
