.dialog {
  background-color: var(--background-light-primary-medium);
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 320px;
  outline: none;
  width: 100%;
}

@media screen(tablet) {
  .dialog {
    /* stylelint-disable-next-line unit-no-unknown */
    border-radius: theme(borderRadius.2xl);
    height: auto;
    max-height: calc(100% - theme(spacing.8));
    max-width: theme(screens.tablet);
    overflow: hidden;
    width: auto;
  }
}
