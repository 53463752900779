.select-listbox {
  background-color: var(--background-light-primary-low);
  display: flex;
  flex-direction: column;
  height: 100dvh;
  outline: none;
  position: fixed;
  width: 100dvw;
}

.select-listbox__header {
  display: flex;
  padding: theme(spacing[3.5]);
}

.select-listbox__title {
  font-size: var(--type-scale-2xl-font-size);
  letter-spacing: var(--type-scale-2xl-letter-spacing);
  line-height: var(--type-scale-2xl-line-height);
}

.select-listbox__close-button {
  align-items: center;
  color: var(--content-dark-brand);
  display: flex;
  font-weight: var(--typeface-weight-bold);
  margin-left: auto;
}

.select-listbox__close-button:hover {
  color: var(--content-dark-hover-brand);
}

.select-listbox__close-button:active {
  color: var(--content-dark-pressed-brand);
}

.select-listbox__close-button-icon {
  display: inline-block;
  height: theme(spacing.8);
  width: theme(spacing.8);
}

.select-listbox__list {
  flex: 1;
  overflow-y: auto;
}

@media screen(tablet) {
  .select-listbox {
    height: auto;
    position: static;
    width: auto;
  }

  .select-listbox__header {
    display: none;
  }

  .select-listbox__list {
    max-height: 300px;
  }
}
