@import url('./SliderRail.css');
@import url('./SliderRoot.css');
@import url('./SliderThumb.css');
@import url('./SliderTrack.css');

.slider {
  --slider-thumb-size: 28px;
  --slider-rail-height: 4px;

  display: inline-flex;
}
