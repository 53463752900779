.input-root {
  align-items: center;
  background-color: var(--background-light-primary-low);
  border: 1px solid var(--content-dark-primary-min);
  border-radius: theme(borderRadius.full);
  display: inline-flex;
  font-size: var(--type-scale-m-font-size);
  gap: theme(spacing[2.5]);
  letter-spacing: var(--type-scale-m-letter-spacing);
  line-height: var(--type-scale-m-line-height);
  padding-left: theme(spacing.6);
  padding-right: theme(spacing.6);
  transition-duration: theme(transitionDuration.150);
  transition-property: color, background-color, border-color, fill;
  transition-timing-function: theme(transitionTimingFunction.DEFAULT);
}

.input-root:hover {
  border-color: var(--content-dark-primary-medium);
}

.input-root:focus-within,
.input-root_focused {
  border-color: var(--content-dark-primary-min);
  box-shadow: 0 2px 2px 0 rgb(51 51 51 / 8%) inset;
}

.input-root_disabled {
  background-color: var(--background-light-primary-medium);
}

.input-root_disabled:hover {
  border-color: var(--content-dark-primary-low);
}

.input-root_error {
  border-color: var(--content-dark-accent);
}

.input-root_form-control {
  width: 100%;
}

.input-root_multiline {
  /* stylelint-disable-next-line unit-no-unknown */
  border-radius: theme(borderRadius.3xl);
}
