.dialog-title {
  background-color: var(--background-light-primary-low);
  display: flex;
  flex-direction: row;
  padding: theme(spacing.5);
}

.dialog-title__text {
  flex: 1 1 0;
  font-size: var(--type-scale-xl-font-size);
  font-weight: var(--typeface-weight-medium);
  letter-spacing: var(--type-scale-xl-letter-spacing);
  line-height: var(--type-scale-xl-line-height);
}

.dialog-title__close-button {
  --dialog-title-close-button-color: var(--content-dark-primary-max);

  align-items: center;
  color: var(--dialog-title-close-button-color);
  display: flex;
  fill: var(--dialog-title-close-button-color);
  font-weight: var(--typeface-weight-bold);
}

.dialog-title__close-button:hover {
  --dialog-title-close-button-color: var(--content-dark-hover-brand);
}

.dialog-title__close-button:active {
  --dialog-title-close-button-color: var(--content-dark-pressed-brand);
}

.dialog-title__close-button-icon {
  display: inline-block;
  height: theme(spacing.8);
  width: theme(spacing.8);
}

.dialog-title__close-button-text {
  display: none;
}
