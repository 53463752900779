/* @deprecated use `NumberedListItem` component from `base-ui` */
.list-numbered-hexagon > li {
  counter-increment: section;
  min-height: 30px;
  padding-left: theme(padding.8);
  padding-top: theme(padding[0.5]);
  position: relative;
}

.list-numbered-hexagon > li::before {
  background: url('../public/images/hexagon-number.svg') no-repeat center;
  color: theme(textColor.dark-primary-max);
  content: counter(section);
  display: inline-block;
  font-family: theme(fontFamily.mono);
  font-size: theme(fontSize.s);
  font-weight: theme(fontWeight.medium);
  height: 26px;
  left: 0;
  line-height: 26px;
  position: absolute;
  text-align: center;
  top: 0;
  width: 24px;
}
