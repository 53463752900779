.product-badge {
  --product-badge-size: 76px;

  align-items: center;
  border-radius: theme(borderRadius.full);
  display: inline-flex;
  flex-direction: column;
  font-weight: theme(fontWeight.medium);
  height: var(--product-badge-size);
  justify-content: center;
  text-align: center;
  width: var(--product-badge-size);
}
