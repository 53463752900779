.textarea-inner {
  background-color: transparent;
  color: var(--content-dark-primary-max);
  flex: 1;
  min-height: 118px;
  min-width: 0;
  outline: none;
  padding-bottom: calc(theme(spacing.4) - 1px);
  padding-top: calc(theme(spacing.4) - 1px);
}

.textarea-inner::placeholder {
  color: var(--content-dark-primary-strong);
}

.textarea-inner_form-control {
  transition-duration: theme(transitionDuration.100);
  transition-property: padding-bottom, padding-top;
  transition-timing-function: theme(transitionTimingFunction.in-out);
}

.textarea-inner_form-control:not([value='']),
.textarea-inner_form-control:focus {
  padding-bottom: calc(theme(spacing.2) - 1px);
  padding-top: calc(theme(spacing.6) - 1px);
}
