.icon-list-item {
  align-items: flex-start;
  display: flex;
  gap: theme(spacing.3);
}

.icon-list-item__icon-container {
  flex-shrink: 0;
  font-size: 1.5rem;
}

.icon-list-item__content {
  padding-top: 1px;
}
