.modal {
  @apply fixed left-0 top-0 z-modal flex h-full w-screen items-center justify-center;

  @supports (height: 100dvh) {
    height: 100dvh;
  }

  /*
   * Experimental solution from MUI Joy
   * https://github.com/mui/material-ui/blob/3d2fb8adca9d7ea3d4ea46998a207bcf5aa04aa1/packages/mui-joy/src/Modal/Modal.tsx#L29-L33
   */
  --unstable-popup-z-index: calc(theme(zIndex.modal) + 1);

  /* target all the listbox (Menu, Select, etc.) that uses popper */
  & ~ [data-popper-placement],
  & ~ [data-popup-placement] {
    --unstable-popup-z-index: calc(theme(zIndex.modal) + 1);
  }
}

.modal__backdrop {
  @apply absolute inset-0 z-[-1] bg-backdrop;
}
