@import url('./CardContent.css');

.card {
  /* Radius value could be used by inner component to properly round corners for example image */
  --card-border-radius: var(--spacing-6);

  background-color: var(--background-light-brand-medium);
  border-radius: var(--card-border-radius);
  overflow: hidden;

  /* Used to scope a11y link with overlay functionality */
  position: relative;
}
