.button {
  align-items: center;
  border-radius: theme(borderRadius.full);
  display: inline-flex;
  font-size: var(--button-font-size);
  font-weight: var(--typeface-weight-medium);
  justify-content: center;
  letter-spacing: var(--button-letter-spacing);
  line-height: var(--button-line-height);
  min-height: var(--button-height);
  outline: none;
  padding-inline: var(--button-inline-padding);
  text-align: center;
  transition-duration: theme(transitionDuration.DEFAULT);
  transition-property: color, background-color, border-color, fill;
  transition-timing-function: theme(transitionTimingFunction.DEFAULT);

  /* Button text should always be on a single line */
  white-space: nowrap;
}

.button:focus-visible {
  outline: revert;
}

.button_large {
  --button-font-size: var(--type-scale-m-font-size);
  --button-letter-spacing: var(--type-scale-m-letter-spacing);
  --button-line-height: var(--type-scale-m-line-height);
  --button-height: 3.5rem;
  --button-inline-padding: var(--spacing-basic-m);
  --button-icon-size: var(--type-scale-2xl-font-size);
}

.button_medium {
  --button-font-size: var(--type-scale-s-font-size);
  --button-letter-spacing: var(--type-scale-s-letter-spacing);
  --button-line-height: var(--type-scale-s-line-height);
  --button-height: 3rem;
  --button-inline-padding: var(--spacing-basic-m);
  --button-icon-size: var(--type-scale-2xl-font-size);
}

.button_small {
  --button-font-size: var(--type-scale-xs-font-size);
  --button-letter-spacing: var(--type-scale-xs-letter-spacing);
  --button-line-height: var(--type-scale-xs-line-height);
  --button-height: 2.25rem;
  --button-inline-padding: var(--spacing-basic-s);
  --button-icon-size: var(--type-scale-m-font-size);
}

.button_filled {
  background-color: var(--button-filled-background-color);
  color: var(--button-filled-color);
}

.button_primary {
  --button-filled-background-color: var(--background-dark-brand);
  --button-filled-color: var(--content-light-primary-strong);
}

.button_primary:hover {
  --button-filled-background-color: var(--background-dark-hover-brand);
}

.button_primary:active,
.button_primary:focus-visible {
  --button-filled-background-color: var(--background-dark-pressed-brand);
}

.button_secondary {
  --button-filled-background-color: var(--background-light-brand-medium);
  --button-filled-color: var(--content-dark-brand);
}

.button_secondary:hover {
  --button-filled-background-color: var(--background-light-brand-high);
}

.button_secondary:active,
.button_secondary:focus-visible {
  --button-filled-background-color: var(--background-light-brand-medium);
}

.button_outlined {
  border: 2px solid var(--content-dark-brand);
  color: var(--content-dark-brand);
}

.button_outlined:hover {
  background-color: var(--background-light-brand-high);
}

.button_outlined:active,
.button_outlined:focus-visible {
  background-color: transparent;
  border-color: var(--content-dark-pressed-brand);
}

.button_text {
  --button-height: auto;
  --button-inline-padding: 0rem;

  color: var(--content-dark-primary-max);
}

.button_text:hover {
  color: var(--content-dark-hover-brand);
}

.button_text:active,
.button_text:focus-visible {
  color: var(--content-dark-pressed-brand);
}

.button[disabled] {
  cursor: not-allowed;
}

.button_filled[disabled] {
  --button-filled-background-color: var(--background-light-primary-high);
  --button-filled-color: var(--content-dark-primary-medium);
}

.button_outlined[disabled] {
  background-color: transparent;
  border-color: var(--content-dark-primary-low);
  color: var(--content-dark-primary-medium);
}

.button_text[disabled] {
  color: var(--content-dark-primary-low);
}

.button__icon {
  font-size: var(--button-icon-size);
}

.button__icon_start {
  margin-inline-end: var(--spacing-basic-3xs);
}

.button__icon_end {
  margin-inline-start: var(--spacing-basic-3xs);
}
