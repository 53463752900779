:root {
  --header-banner-height: 0px;
}

:root:has(
    .ad-server-top-header-banner[data-as-status='ready']:not(
        [data-as-top-banner='desktop-only']
      )
  ) {
  --header-banner-height: 40px;
}

.ad-server-top-header-banner {
  /* for top header we need to cover the entire width of the screen even for screens bigger than 1920px so we need to overwrite the requested banner width */
  width: 100% !important;
}

.ad-server-top-header-banner[data-as-top-banner='responsive'] {
  display: flex;
  justify-content: center;
}

.ad-server-top-header-banner[data-as-status='ready'] [data-as-container] {
  --header-banner-height: 40px;

  height: var(--header-banner-height) !important;
  width: 100% !important;
}

.ad-server-top-header-banner[data-as-status='ready'] iframe {
  height: var(--header-banner-height);
  width: 100%;
}

@media screen(tablet) {
  :root:has(.ad-server-top-header-banner[data-as-status='ready']) {
    --header-banner-height: 100px;
  }

  .ad-server-top-header-banner[data-as-status='ready'] [data-as-container] {
    --header-banner-height: 100px;
  }

  .ad-server-top-header-banner[data-as-top-banner='desktop-only'] {
    display: flex;
    justify-content: center;
  }
}
