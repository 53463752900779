.form-helper-text {
  @apply mt-2 text-s text-dark-primary-medium;
}

.form-helper-text_disabled {
  /* for customization purpose  */
}

.form-helper-text_error {
  @apply text-dark-error-medium;
}

.form-helper-text_form-control {
  margin-top: theme(spacing.2);
}
