.select-root {
  --select-icon-color: var(--content-dark-brand);

  align-items: center;
  background-color: var(--background-light-primary-low);
  border: 1px solid var(--content-dark-primary-min);
  border-radius: theme(borderRadius.full);
  color: var(--content-dark-primary-max);
  display: inline-flex;
  font-size: var(--type-scale-m-font-size);
  font-weight: theme(fontWeight.medium);
  gap: theme(gap.2);
  justify-content: space-between;
  letter-spacing: var(--type-scale-m-letter-spacing);
  line-height: var(--type-scale-m-line-height);
  min-height: 56px;
  outline: none;
  padding: 0 theme(spacing.6);
  text-align: start;
  transition-duration: theme(transitionDuration.150);
  transition-property: color, background-color, border-color, fill;
  transition-timing-function: theme(transitionTimingFunction.in-out);
  width: 100%;
}

.select-root:hover,
.select-root:focus,
.select-root:focus-visible {
  border-color: var(--content-dark-primary-medium);
}

.select-root:disabled {
  --select-icon-color: var(--content-dark-primary-low);

  background-color: var(--background-light-primary-medium);
  border-color: var(--background-light-primary-max);
  color: var(--content-dark-primary-medium);
  cursor: not-allowed;
}

.select-root[aria-expanded='true'] {
  background-color: var(--background-light-brand-low);
  border-color: var(--content-dark-primary-medium);
  border-radius: 1.5rem;
}

.select-root[aria-expanded='true'][data-popup-placement='bottom-start'] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom-width: 0;
  padding-bottom: 1px;
}

.select-root[aria-expanded='true'][data-popup-placement='top-start'] {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-top-width: 0;
  padding-top: 1px;
}

/* https://github.com/tailwindlabs/tailwindcss/issues/9908#issuecomment-1327887975 */
@media not all and (min-width: theme('screens.tablet')) {
  body:has(.select-root_open) {
    overflow: hidden;
  }
}

.select-root__value {
  display: inline-block;
}

.select-root__icon {
  color: var(--select-icon-color);
  height: 24px;
  margin-right: calc(theme(spacing.2) * -1);
  width: 24px;
}

.form-control__select-root {
  overflow: hidden;
}

.form-control__select-root-value {
  position: relative;
  top: theme(spacing.2);
}
