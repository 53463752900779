.rating {
  color: var(--content-light-tertiary);
  display: inline-flex;
}

.rating__item {
  /* for customization purpose  */
}

.rating__input {
  @apply sr-only;
}

.rating__icon {
  /* for customization purpose  */
}

.rating__icon_filled {
  /* for customization purpose  */
}
